<template>
  <div id="mian">
    <div class="header">
      <span
        @click="$router.push('/organization_jghz')"
        v-auth="'DATA:SUMMARY:ORGANIZATION'"
        >机构汇总</span
      >
      <span
        @click="$router.push('/channel_qdhz')"
        v-auth="'DATA:SUMMARY:CHANNEL'"
        >渠道汇总</span
      >
      <span
        @click="$router.push('/commercial_shhz')"
        v-auth="'DATA:SUMMARY:MERCHANT'"
        >商户汇总</span
      >
      <!-- <span  @click="$router.push('/service_fwshz')">服务商汇总</span> -->
      <span id="fzcolor" v-auth="'DATA:SUMMARY:AGENT'">代理商汇总</span>
    </div>
    <!-- 批量订单查询 -->
    <div>
      <!-- 上面表单框 -->
      <div class="con_from">
        <div class="lineP">
          <div class="item">
            <i>代理商名称</i>
            <el-input
              v-model="query.keyword"
              maxlength="128"
              suffix-icon="el-icon-search"
              placeholder="代理商名称/代理商编号"
            ></el-input>
          </div>
          <div class="item itemTime">
            <i>账单日期</i>
            <el-date-picker
              v-model="query.startDate"
              value-format="yyyy-MM-dd"
              type="date"
              placeholder="选择日期"
            >
            </el-date-picker>
            <span style="padding-left: 10px; padding-right: 10px">-</span>
            <el-date-picker
              v-model="query.endDate"
              value-format="yyyy-MM-dd"
              type="date"
              placeholder="选择日期"
            >
            </el-date-picker>
          </div>
          <div class="itembtn" style="width:12%;">
            <el-button class="searchBt" @click="getLists(true)">查询</el-button>
          </div>
        </div>
      </div>
      <!-- 中间交易账目 -->
      <div class="jiaoyi">
        <div class="jiaoyi_box">
          <p><i>交易总数（笔）</i></p>
          <p>
            <b>{{ statistics.totalTradeNum ? statistics.totalTradeNum : 0 }}</b>
          </p>
        </div>
        <div class="jiaoyi_box">
          <p><i>交易金额（元）</i></p>
          <p>
            <b>{{ (statistics.totalTradeAmount / 100) | formatMoney }}</b>
          </p>
        </div>
        <div class="jiaoyi_box">
          <p><i>退款总数（笔）</i></p>
          <p>
            <b>{{
              statistics.totalRefundNum ? statistics.totalRefundNum : 0
            }}</b>
          </p>
        </div>
        <div class="jiaoyi_box">
          <p><i>退款金额（元）</i></p>
          <p>
            <b>{{ (statistics.totalRefundAmount / 100) | formatMoney }}</b>
          </p>
        </div>
        <div class="jiaoyi_box">
          <p><i>分润金额（元）</i></p>
          <p>
            <b>{{ (statistics.totalProfit / 100) | formatMoney }}</b>
          </p>
        </div>
      </div>
      <div class="tab1">
        <table class="tab">
          <tr class="tab_title">
            <td>
              <p>账单日期</p>
            </td>
            <td>
              <p>代理商名称</p>
              <p>代理商编号</p>
            </td>
            <td>
              <p>交易笔数(笔)</p>
            </td>
            <td>
              <p>交易金额(元)</p>
            </td>
            <td>
              <p>退款笔数(笔)</p>
            </td>
            <td>
              <p>退款金额(元)</p>
            </td>
            <td>
              <p>分润金额(元)</p>
            </td>
            <td style="padding-right: 10px">
              <p>操作</p>
            </td>
          </tr>
          <tr v-for="(v, i) in tabData" :key="i">
            <td>
              <p>{{ v.settleDate }}</p>
            </td>
            <td>
              <p>{{ v.xname }}</p>
              <p>{{ v.xid }}</p>
            </td>
            <td>
              <p>{{ v.tradeNum }}</p>
            </td>
            <td>
              <p>{{ (v.tradeAmount / 100) | formatMoney }}</p>
            </td>
            <td>
              <p>{{ v.refundNum }}</p>
            </td>
            <td>
              <p>{{ (v.refundAmount / 100) | formatMoney }}</p>
            </td>
            <td>
              <p>{{ (v.profit / 100) | formatMoney }}</p>
            </td>
            <td style="padding-right: 10px">
              <p><i class="lianjie" @click="detailTab(v)" v-auth="'DATA:SUMMARY:AGENT/DETAIL'">详情</i></p>
            </td>
          </tr>
        </table>
      </div>
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="query.pageNo"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="query.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalNum"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { getAgents } from "@/api/data/clearing.js";
import { formatDate } from "@/utils/common.js";
import { mapState, mapMutations } from "vuex";

export default {
  data() {
    return {
      query: {
        pageNo: 1,
        pageSize: 10,
        startDate: formatDate(+new Date() - 24 * 60 * 60 * 1000, "yyyy-MM-dd"),
        endDate: formatDate(+new Date() - 24 * 60 * 60 * 1000, "yyyy-MM-dd"),
        keyword: "",
      },
      tabData: [],
      statistics: {
        totalTradeNum: 0,
        totalTradeAmount: 0,
        totalRefundNum: 0,
        totalRefundAmount: 0,
        totalProfit: 0,
      },
      totalNum: 0,
      crumbs: "批量查询",
    };
  },
  computed: {
    ...mapState({
      dlshzDetail: "data_dlshzQuery",
    }),
  },
  created() {
    if (this.dlshzDetail) {
      this.query = this.dlshzDetail.query;
    }
    this.getLists(false);
    this.$enter(this.$route.path, this.getLists);
  },
  methods: {
    ...mapMutations({
      setDlshzDeal: "data_setDlshz",
    }),
    // 获取列表详情
    getLists(reset) {
      if (reset) {
        this.query.pageNo = 1;
      }
      this.query.startDate = this.query.startDate ? this.query.startDate : "";
      this.query.endDate = this.query.endDate ? this.query.endDate : "";
      this.query.keyword = this.query.keyword.trim();
      if (!this.query.startDate || !this.query.endDate) {
        this.$message.error("账单日期时间段必选");
        return;
      }
      getAgents(this.query).then((res) => {
        if (res.resultStatus) {
          this.tabData = res.resultData.settles;
          this.totalNum = res.resultData.totalNum;
          this.statistics = res.resultData.statistics
            ? res.resultData.statistics
            : {
                totalTradeNum: 0,
                totalTradeAmount: 0,
                totalRefundNum: 0,
                totalRefundAmount: 0,
                totalProfit: 0,
              };
          if (this.tabData.length == 0) {
            this.$message({
              message: "暂无数据",
              duration: 1500,
            });
          }
        }
      });
    },
    // 页面大小改变
    handleSizeChange(size) {
      this.query.pageSize = size;
      this.getLists(true);
    },
    handleCurrentChange(page) {
      this.query.pageNo = page;
      this.getLists(false);
    },
    //详情
    detailTab(value) {
      this.$router.push({
        name: "agent_dlshz_detail",
        query: {
          id: value.xid,
          startDate: this.query.startDate,
          endDate: this.query.endDate,
        },
      });
    },
    //菜单切换
    navClick(data) {
      this.crumbs = data;
    },
  },
  beforeDestroy() {
    let data = {
      query: this.query,
    };
    this.setDlshzDeal(data);
  },
};
</script>
<style scoped>
.con_from .lineP .item {
    width: 28%;
    margin-left: 4.5%;
}
.con_from .lineP .item:first-child {
    margin-left: 0%;
}
.con_from .lineP .item i {
  width: 84px;
}
.con_from .lineP .itemTime {
  width: 49.321%;
}
.con_from .lineP .item .el-input , .con_from .lineP .item .el-select{
  width: calc(100% - 84px - 10px);
}
.con_from .lineP .itemTime .el-input{
  width: calc((100% - 84px - 24.67px - 10px) / 2);
}
.jiaoyi_box {
  width: 18.5%;
}

</style>
